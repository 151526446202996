import * as LabelPrimitive from "@radix-ui/react-label";
import { type VariantProps, cva } from "class-variance-authority";
import React, { type JSX } from "react";
import { cn } from "utils/utils";

export const LabelVariants = cva("font-medium", {
  variants: {
    variant: {
      white: "text-white",
      secondary: "text-secondary",
      secondaryDarker: "text-secondaryDarker",
      primary: "text-primary",
    },
    // <!> Not change this "size" values to avoid breaking the UI, they aren't used randomly they are the true value of project designed in figma
    size: {
      L: "text-lg leading-[150%]",
      M: "text-base leading-[150%]",
      S: "text-sm leading-[130%]",
      XS: "text-xs leading-[140%]",
    },
  },
  defaultVariants: {
    size: "M",
    variant: "secondary",
  },
});

export interface LabelProps
  extends React.HTMLAttributes<HTMLLabelElement | HTMLHeadingElement>,
    VariantProps<typeof LabelVariants> {
  as?: keyof Pick<
    JSX.IntrinsicElements,
    "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "label" | "p" | "span"
  >;
}

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & LabelProps
>(({ className, variant, size = "L", as = "label", color, ...props }, ref) => {
  const Comp = as === "label" ? LabelPrimitive.Root : as;
  if (as === "label") {
    return (
      <LabelPrimitive.Root
        className={cn(LabelVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
  return (
    <Comp
      className={cn(LabelVariants({ variant, size, className }))}
      {...props}
    />
  );
});
Label.displayName = "Label";
export { Label };
